<template>
  <div class="container-fluid">
    <span class="resize-loading" v-if="!organization">
      <span class="loader"></span>
    </span>
    <organization-form
      v-if="organization"
      :loading="loading"
      :organizationData="organization"
      :formErrors="formErrors"
      @organizationSubmitted="handleSubmit"
      @onCloseOrganizationModal="onCloseOrganizationModal"
      @formChanged="() => (alertLeave = true)"
    />
  </div>
</template>

<script>
import OrganizationForm from "../partials/OrganizationForm.vue";
import { cloneDeep } from "lodash";
import alertLeave from "@/mixins/alert-leave-mixin";

export default {
  layout: "DashboardLayout",

  components: { OrganizationForm },

  mixins: [alertLeave],

  props: {
    organizationId: {
      type: [String, Number],
      required: true,
    },
  },

  data() {
    return {
      organization: null,
      formErrors: null,
      loading: false,
    };
  },

  created() {
    this.get();
  },

  methods: {
    async get() {
      this.loading = true;
      try {
        await this.$store.dispatch("organizations/get", this.organizationId);
        this.organization = this.$store.getters["organizations/organization"];
        this.loading = false;
      } catch (error) {
        this.$notify({
          type: "danger",
          message: this.$t("ERRORS.SOMETHING_WENT_WRONG"),
        });
        this.loading = false;
      }
    },

    async handleSubmit(organization) {
      this.loading = true;
      const organizationData = cloneDeep(organization);

      try {
        await this.$store.dispatch("organizations/update", organizationData);
        this.$notify({
          type: "success",
          message: this.$t("ORGANIZATIONS.ORGANIZATION_UPDATED"),
        });
        this.$emit("onViewOrganization", organization, true);
        this.loading = false;
      } catch (error) {
        this.$notify({
          type: "danger",
          message: this.$t("ERRORS.SOMETHING_WENT_WRONG"),
        });
        this.formErrors = error.response.data.errors;
        this.loading = false;
      }
    },

    onCloseOrganizationModal() {
      this.$emit("onViewOrganization", this.organization);
    },
  },
};
</script>
