<template>
  <div>
    <div
      v-for="model in modelsPermissions"
      :key="model.name"
      class="col-12 permission-wrapper"
    >
      <h4 class="form-control-label">
        {{ $t(`COMMON.${model.name}`) }}
      </h4>
      <div class="permissions-wrapper-inner">
        <div
          class="permissions-wrapper-inner-item"
          v-for="permission in model.permissions"
          :key="permission.key"
        >
          <icon-check :checked="permissionsData.includes(permission.key)" />
          {{ $t(`PERMISSIONS.${permission.key}`) }}
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import IconCheck from "@/components/IconCheck.vue";
import models from "@/constants/models";

export default {
  name: "permissions-table",

  components: { IconCheck },

  props: ["permissionsData", "organization"],

  data() {
    return { models: models, permissions: [] };
  },

  computed: {
    modelsPermissions() {
      const modelsPerms = [];
      const foundPerms = [];
      for (const model in models) {
        const permissions = [];
        for (const perm of this.permissions) {
          if (!foundPerms.includes(perm.key)) {
            let modelPermKey = `_${model}`;
            if (model == models.APP) {
              modelPermKey = `_${model}_`;
            }
            if (perm.key.includes(modelPermKey)) {
              permissions.push(perm);
              foundPerms.push(perm.key);
            }
          }
        }
        if (permissions.length > 0) {
          modelsPerms.push({ name: model, permissions: permissions });
        }
      }
      return modelsPerms;
    },
  },

  created() {
    this.getPermissions();
  },

  methods: {
    async getPermissions() {
      try {
        await this.$store.dispatch("permissions/list", {
          sort: "name",
          page: {
            number: 1,
            size: 999999,
          },
          filter: {
            ...(this.organization ? { scope: 40 } : {}),
          },
        });
        this.permissions = await this.$store.getters["permissions/list"];
      } catch (error) {
        this.$notify({
          type: "danger",
          message: this.$t("ERRORS.SOMETHING_WENT_WRONG"),
        });
      }
    },
  },

  mounted() {},

  watch: {
    permissionsData(permissionsData) {
      this.getPermissions();
    },
  },
};
</script>
