<template>
  <div>
    <div class="page-wrapper-table">
      <div class="page-wrapper-table-header">
        <div class="page-wrapper-table-header-left">
          <base-input
            v-model="query"
            type="search"
            class="search"
            prepend-icon="far fa-search"
            :placeholder="$t('COMMON.SEARCH')"
            clearable
          />
          <organization-selector
            v-if="
              !filterOrganization &&
              $currentUserCan($permissions.PERM_VIEW_ANY_ORGANIZATIONS)
            "
            @organizationChanged="
              (organizationId) => (selectedOrganization = organizationId)
            "
          />
          <package-selector
            v-if="
              !filterPackage &&
              $currentUserCan($permissions.PERM_VIEW_ANY_PACKAGES)
            "
            @packageChanged="(packageId) => (selectedPackage = packageId)"
          />
          <base-input placeholder="Dates">
            <flat-picker
              :config="{
                allowInput: true,
                mode: 'range',
                locale: flatPickrLocale,
              }"
              class="form-control datepicker"
              v-model="startDateRange"
              :placeholder="$t('SUBSCRIPTIONS.START_TIME')"
            >
            </flat-picker>
          </base-input>
          <base-input placeholder="Dates">
            <flat-picker
              :config="{
                allowInput: true,
                mode: 'range',
                locale: flatPickrLocale,
              }"
              class="form-control datepicker"
              v-model="endDateRange"
              :placeholder="$t('SUBSCRIPTIONS.END_TIME')"
            >
            </flat-picker>
          </base-input>
        </div>
        <div class="page-wrapper-table-header-right">
          <el-select
            class="select-primary pagination-select"
            v-model="pagination.perPage"
            :placeholder="$t('COMMON.PER_PAGE')"
          >
            <el-option
              class="select-primary"
              v-for="item in pagination.perPageOptions"
              :key="item"
              :label="item"
              :value="item"
            >
            </el-option>
          </el-select>
        </div>
      </div>
      <div v-if="subscriptions.length > 0" class="page-wrapper-table-body">
        <div class="page-wrapper-table-body-inner">
          <el-table
            class="align-items-center table-flush"
            header-row-class-name="thead-light"
            :data="subscriptions"
            @sort-change="sortChange"
          >
            <div class="table-loading" slot="empty" v-if="loading">
              <img src="/img/loading.gif" />
            </div>
            <el-table-column width="60">
              <template v-slot="{ row }">
                <span
                  v-if="isSubscriptionExpired(row)"
                  class="text-danger text-bold"
                >
                  <img
                    src="../../../../../../public/img/kw-times.svg"
                    alt="icon"
                  />
                </span>
                <span
                  v-if="!isSubscriptionExpired(row)"
                  class="text-success text-bold"
                >
                  <img
                    src="../../../../../../public/img/kw-green-check.svg"
                    alt="icon"
                  />
                </span>
              </template>
            </el-table-column>
            <el-table-column
              :label="$t('COMMON.CODE')"
              prop="code"
              sortable="custom"
              min-width="200"
            />

            <el-table-column
              sortable="custom"
              :label="$t('SUBSCRIPTIONS.START_TIME')"
              prop="start_time"
              min-width="180"
            />

            <el-table-column
              sortable="custom"
              :label="$t('SUBSCRIPTIONS.END_TIME')"
              prop="end_time"
              min-width="180"
            />

            <el-table-column
              :label="$t('COMMON.PACKAGE')"
              sortable="custom"
              min-width="220"
              v-if="
                !filterPackage &&
                $currentUserCan($permissions.PERM_VIEW_ANY_PACKAGES)
              "
            >
              <template v-slot="{ row }">
                <span>
                  {{ row.package ? row.package.name : null }}
                </span>
              </template>
            </el-table-column>

            <el-table-column
              :label="$t('COMMON.ORGANIZATION')"
              sortable="custom"
              min-width="220"
              v-if="
                !filterOrganization &&
                $currentUserCan($permissions.PERM_VIEW_ANY_ORGANIZATIONS)
              "
            >
              <template v-slot="{ row }">
                <organization :organization="row.organization" />
              </template>
            </el-table-column>

            <el-table-column
              :label="$t('COMMON.CREATED_AT')"
              prop="created_at"
              sortable="custom"
              min-width="180"
            />

            <el-table-column fixed="right" min-width="70">
              <div slot-scope="{ row }" class="table-actions">
                <el-dropdown trigger="click">
                  <span class="el-dropdown-link">
                    <i class="far fa-ellipsis-v"></i>
                  </span>
                  <el-dropdown-menu slot="dropdown" class="actions-dropdown">
                    <el-dropdown-item
                      v-if="
                        $currentUserCan($permissions.PERM_VIEW_SUBSCRIPTIONS)
                      "
                    >
                      <a
                        type="text"
                        @click="viewSubscription(row)"
                        class="table-action view"
                        data-toggle="tooltip"
                      >
                        <span class="icon">
                          <img
                            src="../../../../../../public/img/kw-eye.svg"
                            alt="icon"
                          />
                        </span>
                        <span class="text">{{
                          $t("COMMON.VIEW_DETAILS")
                        }}</span>
                      </a>
                    </el-dropdown-item>
                    <el-dropdown-item
                      v-if="
                        $currentUserCan($permissions.PERM_EDIT_SUBSCRIPTIONS)
                      "
                    >
                      <a
                        type="text"
                        @click="editSubscription(row)"
                        class="table-action edit"
                        data-toggle="tooltip"
                      >
                        <span class="icon">
                          <img
                            src="../../../../../../public/img/kw-edit.svg"
                            alt="icon"
                          />
                        </span>
                        <span class="text">
                          {{ $t("COMMON.EDIT") }}
                        </span>
                      </a>
                    </el-dropdown-item>
                    <el-dropdown-item
                      v-if="
                        $currentUserCan(
                          $permissions.PERM_DELETE_SUBSCRIPTIONS
                        ) && false
                      "
                    >
                      <a
                        type="text"
                        @click="deleteSubscription(row)"
                        class="table-action delete"
                        data-toggle="tooltip"
                      >
                        <span class="icon">
                          <img
                            src="../../../../../../public/img/kw-delete.svg"
                            alt="icon"
                          />
                        </span>
                        <span class="text">
                          {{ $t("COMMON.DELETE") }}
                        </span>
                      </a>
                    </el-dropdown-item>
                  </el-dropdown-menu>
                </el-dropdown>
              </div>
            </el-table-column>
          </el-table>
        </div>
      </div>
      <div
        v-if="subscriptions.length > 0"
        slot="footer"
        class="page-wrapper-table-footer"
      >
        <p class="card-category">
          {{
            $t("COMMON.DISPLAY_FROM_X_TO_X_OF_X_ENTRIES", {
              from: total ? from + 1 : 0,
              to: to,
              of: total,
            })
          }}

          <span v-if="selectedRows.length">
            &nbsp; &nbsp;
            {{ $t("COMMON.X_LINES_SELECTED", { count: selectedRows.length }) }}
          </span>
        </p>
        <base-pagination
          class="pagination-no-border"
          v-model="pagination.currentPage"
          :per-page="pagination.perPage"
          :total="total"
        />
      </div>
    </div>
    <div v-if="subscriptions.length === 0" class="no-data">
      <div class="no-data-inner">
        <img src="../../../../../../public/img/kw-empty-box.svg" alt="icon" />
        <p>{{ $t("SUBSCRIPTIONS.NO_DATA") }}</p>
        <base-button
          class="kw-button add"
          icon
          size="sm"
          @click="onAddSubscription"
          v-if="$currentUserCan($permissions.PERM_CREATE_SUBSCRIPTIONS)"
        >
          <span class="btn-inner--icon">
            <i class="far fa-plus"></i>
          </span>
          <span class="btn-inner--text">{{
            $t("SUBSCRIPTIONS.ADD_SUBSCRIPTION")
          }}</span>
        </base-button>
      </div>
    </div>
  </div>
</template>
<script>
import _ from "lodash";
import {
  Table,
  TableColumn,
  DropdownMenu,
  DropdownItem,
  Dropdown,
  Tooltip,
  Select,
  Option,
  Button,
} from "element-ui";
import moment from "moment";
import swal from "sweetalert2";
import "sweetalert2/dist/sweetalert2.css";
import flatPicker from "vue-flatpickr-component";
import { French } from "flatpickr/dist/l10n/fr.js";
import "flatpickr/dist/flatpickr.css";
import requestErrorMixin from "@/mixins/request-error-mixin";
import { BasePagination } from "@/components";
import OrganizationSelector from "@/components/OrganizationSelector.vue";
import PackageSelector from "@/components/PackageSelector.vue";

export default {
  name: "subscription-list-table",

  components: {
    BasePagination,
    OrganizationSelector,
    PackageSelector,
    flatPicker,
    [Tooltip.name]: Tooltip,
    [Table.name]: Table,
    [TableColumn.name]: TableColumn,
    [Dropdown.name]: Dropdown,
    [DropdownItem.name]: DropdownItem,
    [DropdownMenu.name]: DropdownMenu,
    [Select.name]: Select,
    [Option.name]: Option,
    [Button.name]: Button,
  },

  mixins: [requestErrorMixin],

  props: {
    filterOrganization: {
      type: String,
      default: null,
      description: "Organization id",
    },
    filterPackage: {
      type: String,
      default: null,
      description: "Package id",
    },
  },

  data() {
    return {
      flatPickrLocale: French,
      query: null,
      selectedRows: [],
      sort: "-created_at",
      total: 0,
      pagination: {
        perPage: 20,
        currentPage: 1,
        perPageOptions: [20, 50, 100, 500],
      },
      subscriptions: [],
      loading: true,
      selectedOrganization: null,
      selectedPackage: null,
      startDateRange: null,
      endDateRange: null,
    };
  },

  computed: {
    from() {
      return this.pagination.perPage * (this.pagination.currentPage - 1);
    },

    to() {
      let highBound = this.from + this.pagination.perPage;
      if (this.total < highBound) {
        highBound = this.total;
      }
      return highBound;
    },
  },

  watch: {
    query: {
      handler: "getListDebounced",
      immediate: true,
    },
    pagination: {
      handler: "getList",
      immediate: false,
      deep: true,
    },
    filterOrganization: {
      handler: "getListDebounced",
      immediate: true,
    },
    selectedOrganization: {
      handler: "getListDebounced",
      immediate: true,
    },
    filterPackage: {
      handler: "getListDebounced",
      immediate: true,
    },
    selectedPackage: {
      handler: "getListDebounced",
      immediate: true,
    },
    startDateRange: {
      handler: "getListDebounced",
      immediate: false,
      deep: true,
    },
    endDateRange: {
      handler: "getListDebounced",
      immediate: false,
      deep: true,
    },
  },

  methods: {
    getListDebounced: _.debounce(function () {
      this.getList();
    }, 300),

    async getList() {
      try {
        this.loading = true;
        const vStartDateRange = this.startDateRange
          ? this.startDateRange.split(this.flatPickrLocale.rangeSeparator)
          : [];
        const vEndDateRange = this.endDateRange
          ? this.endDateRange.split(this.flatPickrLocale.rangeSeparator)
          : [];
        let params = {
          ...(this.sort ? { sort: this.sort } : {}),
          filter: {
            ...(this.query ? { code: this.query } : {}),
            ...(vStartDateRange.length == 2
              ? {
                  start_time: [
                    vStartDateRange[0] + " 00:00:00",
                    vStartDateRange[1] + " 23:59:59",
                  ],
                }
              : {}),
            ...(vEndDateRange.length == 2
              ? {
                  end_time: [
                    vEndDateRange[0] + " 00:00:00",
                    vEndDateRange[1] + " 23:59:59",
                  ],
                }
              : {}),
          },
          page: {
            number: this.pagination.currentPage,
            size: this.pagination.perPage,
          },
          include: "organization,package",
        };

        if (this.filterOrganization) {
          params = {
            ...params,
            filter: { ...params.filter, organization: this.filterOrganization },
          };
        }
        if (this.selectedOrganization) {
          params = {
            ...params,
            filter: {
              ...params.filter,
              organization: this.selectedOrganization,
            },
          };
        }

        if (this.filterPackage) {
          params = {
            ...params,
            filter: { ...params.filter, package: this.filterPackage },
          };
        }
        if (this.selectedPackage) {
          params = {
            ...params,
            filter: {
              ...params.filter,
              package: this.selectedPackage,
            },
          };
        }

        await this.$store.dispatch("subscriptions/list", params);
        this.subscriptions = this.$store.getters["subscriptions/list"];
        this.total = this.$store.getters["subscriptions/listTotal"];
        this.loading = false;
      } catch (error) {
        this.$notify({
          type: "danger",
          message: this.$t("ERRORS.SOMETHING_WENT_WRONG"),
        });
      }
    },

    deleteSubscription(subscription) {
      this.$emit("onDeleteSubscription", subscription);
    },

    viewSubscription(subscription) {
      this.$emit("onViewSubscription", subscription);
    },

    onAddSubscription() {
      this.$emit("onAddSubscription");
    },

    editSubscription(subscription) {
      this.$emit("onEditSubscription", subscription);
    },

    isSubscriptionExpired(subscription) {
      return moment(subscription.end_time) < moment();
    },

    sortChange({ prop, order }) {
      if (order === "descending") {
        this.sort = `-${prop}`;
      } else {
        this.sort = `${prop}`;
      }
      this.getList();
    },
  },
};
</script>
