<template>
  <div class="kw-tabs-wrapper-content">
    <!--<h3>{{ organization.name }}</h3>-->
    <h3>{{ $t("COMMON.GENERAL_INFORMATIONS") }}</h3>
    <div class="all-infos">
      <div class="all-infos-image">
        <img
          v-if="organization.logo"
          :src="`${organization.logo}`"
          class="argon-image"
        />
        <img
          v-else
          src="/img/kw-default-image.svg"
          class="argon-image default"
        />
      </div>
      <div class="all-infos-text">
        <dl class="row">
          <dt>{{ $t("COMMON.NAME") }}</dt>
          <dd>
            {{ organization.name }}
          </dd>
        </dl>

        <dl class="row">
          <dt>{{ $t("COMMON.SLUG") }}</dt>
          <dd>
            <copy-element v-if="organization.slug" :element="$portalUrl(organization.slug)" />
            <!-- <a
              class="text-underline"
              :href="$portalUrl(organization.slug)"
              target="_blank"
              v-if="organization.slug"
            >
              {{ $portalUrl(organization.slug) }}
            </a> -->
          </dd>
        </dl>

        <dl class="row">
          <dt>{{ $t("COMMON.EXCERPT") }}</dt>
          <dd>
            <div v-html="organization.excerpt"></div>
          </dd>
        </dl>

        <dl class="row">
          <dt>{{ $t("COMMON.EMAIL") }}</dt>
          <dd>
            {{ organization.email }}
          </dd>
        </dl>

        <dl class="row">
          <dt>
            {{ $t("COMMON.PHONE") }}
          </dt>
          <dd>
            {{
              organization.phone_type
                ? $t(`COMMON.PHONE_TYPE_${organization.phone_type}`)
                : null
            }}:
            {{ organization.phone }}
            {{
              organization.phone_extension
                ? ` (ext: ${organization.phone_extension})`
                : null
            }}
          </dd>
        </dl>

        <dl class="row">
          <dt>
            {{ $t("COMMON.OTHER_PHONES") }}
          </dt>
          <dd>
            <span
              v-for="(line, index) in organization.other_phones"
              :key="index"
            >
              {{ $t(`COMMON.PHONE_TYPE_${line.type}`) }}:
              {{ line.phoneNumber }}
              {{ line.extension ? ` (ext: ${line.extension})` : null }} <br />
            </span>
          </dd>
        </dl>

        <dl class="row">
          <dt>{{ $t("COMMON.ADDRESS") }}</dt>
          <dd>
            {{ organization.address }}
          </dd>
        </dl>

        <dl
          class="row"
          v-if="$currentUserCan($permissions.PERM_VIEW_ANY_RESELLERS)"
        >
          <dt>{{ $t("COMMON.RESELLER") }}</dt>
          <dd>
            <reseller :reseller="organization.reseller" />
          </dd>
        </dl>

        <dl
          class="row"
          v-if="$currentUserCan($permissions.PERM_VIEW_ANY_USERS)"
        >
          <dt>{{ $t("COMMON.OWNER") }}</dt>
          <dd>
            <router-link :to="$objectViewRoute(organization.owner)">
              {{
                `${organization.owner.firstname} ${organization.owner.lastname} - ${organization.owner.email}`
              }}
            </router-link>
          </dd>
        </dl>

        <dl class="row">
          <dt>{{ $t("COMMON.DEFAULT_TAXES") }}</dt>
          <dd>
            <dl class="row" v-for="(tax, key) in organization.taxes" :key="key">
              <dt>{{ tax.name }}</dt>
              <dd v-if="tax.type === TAX_TYPE_PERCENTAGE">{{ tax.value }} %</dd>
              <dd v-if="tax.type === TAX_TYPE_AMOUNT">
                {{ $formatCurrency(tax.value) }}
              </dd>
            </dl>
          </dd>
        </dl>

        <dl class="row">
          <dt>{{ $t("COMMON.CREATED_AT") }}</dt>
          <dd v-if="organization.created_at">
            {{ $formatDate(organization.created_at) }}
          </dd>
        </dl>

        <dl class="row">
          <dt>{{ $t("COMMON.UPDATED_AT") }}</dt>
          <dd v-if="organization.updated_at">
            {{ $formatDate(organization.updated_at) }}
          </dd>
        </dl>

        <div class="title">
          <h3>{{ $t("COMMON.BILLING_ADDRESS") }}</h3>
        </div>

        <billing-informations-view :billingInformations="organization" />
      </div>
    </div>
  </div>
</template>

<script>
import models from "@/constants/models";
import { TAX_TYPE_PERCENTAGE, TAX_TYPE_AMOUNT } from "@/constants/taxes";
import BillingInformationsView from "@/components/BillingInformationsView.vue";
import CopyElement from "@/components/CopyElement.vue";

export default {
  name: "organization-view-global",

  components: { BillingInformationsView, CopyElement },

  props: ["organization"],

  data() {
    return {
      models: models,
      TAX_TYPE_AMOUNT: TAX_TYPE_AMOUNT,
      TAX_TYPE_PERCENTAGE: TAX_TYPE_PERCENTAGE,
    };
  },

  computed: {},

  created() {},

  methods: {},

  mounted() {},

  watch: {
    organization(organization) {},
  },
};
</script>
